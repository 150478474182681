import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import * as selectors from '@src/store/selectors';
import { clearQueryString } from '@src/utils/deprecatedCommon';
import defaultFavicon from '@src/assets/images/theme/favicon.png';
import seoDefaultImage from './images/teaches-default-img.jpg';
import seoDefaultLogoImage from './images/teaches-logo.png';

const findTargetPageData = (url, pages) => {
	let targetPage = pages?.find(page => {
		if (url === '/') {
			return page?.content?.key === 'home';
		}
		return false;
	});

	if (url !== '/') {
		const urlPage = pages.find(page => page?.content?.allPath === url);

		targetPage = urlPage || targetPage;
	}

	return targetPage;
};

const makeCustomizeSeoMeta = ({ seoData, schoolName }) => {
	const title = seoData?.seoTitle || `${schoolName}｜線上課程學校`;
	const description =
    seoData?.seoDescription ||
    `線上課程平台正夯，${schoolName}也開課了！愛自學的你，快來看看老師自己創辦的線上學校吧！沒有時間地點限制，在家就可以學習專業知識。`;

	return {
		title, description,
	};
};

const BasicSeo = ({ domain }) => {
	const themeData = useSelector(selectors.getThemeSelector);
	const router = useRouter();

	const pathname = clearQueryString(router.asPath);

	const targetPage = findTargetPageData(pathname, themeData?.pages || []);
	const schoolName = themeData?.themes?.name || 'Teaches';
	const seoData = targetPage?.content?.seoData;

	const favicon = themeData?.themes?.icon_link?.link || defaultFavicon;
	const touchIcon = favicon || seoDefaultLogoImage;
	const { title, description } = makeCustomizeSeoMeta({
		seoData, schoolName,
	});
	const image = seoData?.seoImage || seoDefaultImage;
	const url = `${domain}${pathname}`;

	return (
		<Head>
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
			/>
			<link rel="icon" href={favicon} />
			<link rel="apple-touch-icon" href={touchIcon} />
			<title>{title}</title>
			<meta name="description" content={description} key="description" />
			<meta property="og:title" content={title} key="og-title" />
			<meta property="og:description" content={description} key="og-description" />
			<meta property="og:image" content={image} key="og-image" />
			<meta property="og:url" content={url} />
			<meta property="og:type" content="website" />
			<link
				rel="alternate"
				hreflang={router.locale}
				href={`${domain}/${router.locale}`}
			/>
			<link rel="canonical" href={domain} />
		</Head>
	);
};

BasicSeo.propTypes = {
	domain: PropTypes.string.isRequired,
};

export default BasicSeo;
