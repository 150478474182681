import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_LEARNING_PATHS_CALL,
	GET_MINE_LEARNING_PATHS_CALL,
	GET_LEARNING_PATHS_BY_SHORT_LINK_CALL,
	JOIN_LEARNING_PATHS_CALL,
	LEAVE_LEARNING_PATHS_CALL,
	LEARNING_PATHS_PROGRESS_CALL,
	GET_LEARNING_PATHS_COMMODITIES_CALL,
	POST_LEARNING_PATHS_CERTIFICATE_CALL,
} from '@src/store/types/learningPath';

import {
	getLearningPathsDone,
	getLearningPathsFail,
	getMineLearningPathsDone,
	getMineLearningPathsFail,
	getLearningPathsByShortLinkDone,
	getLearningPathsByShortLinkFail,
	joinLearningPathDone,
	joinLearningPathFail,
	leaveLearningPathDone,
	leaveLearningPathFail,
	learningProgressCall,
	learningProgressDone,
	learningProgressFail,
	getLearningPathsCommoditiesDone,
	getLearningPathsCommoditiesFail,
	postLearningPathsCertificateDone,
	postLearningPathsCertificateFail,
} from '@src/store/actions/learningPath';
import {
	getLearningPaths,
	getMineLearningPaths,
	getLearningPathsByShortLink,
	joinLearningPaths,
	leaveLearningPaths,
	learningPathsProgress,
	getLearningPathsCommodities,
	postLearningPathsCertificate,
} from '@src/services/learningPath';
import { MODAL_TYPE_LOGIN } from '@src/const/common';
import * as actions from '@src/store/actions';
import * as selectors from '@src/store/selectors';

export const getLearningPathsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEARNING_PATHS_CALL),
	mergeMap(
		({ payload }) => fromPromise(getLearningPaths(payload)).pipe(
			mergeMap(res => of(getLearningPathsDone({ res, key: payload.key }))),
			catchError(error => of(getLearningPathsFail({ error }))),
		),
	),
);

export const getMineLearningPathsEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_MINE_LEARNING_PATHS_CALL),
	switchMap(
		({ payload }) => fromPromise(getMineLearningPaths(payload)).pipe(
			switchMap(res => of(getMineLearningPathsDone({ res }))),
			catchError(error => of(getMineLearningPathsFail({ error }))),
		),
	),
);

export const getLearningPathsByShortLinkEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEARNING_PATHS_BY_SHORT_LINK_CALL),
	switchMap(
		({ payload }) => fromPromise(getLearningPathsByShortLink(payload)).pipe(
			switchMap(res => of(getLearningPathsByShortLinkDone({ res }))),
			catchError(error => of(getLearningPathsByShortLinkFail({ error }))),
		),
	),
);

export const joinLearningPathEpic = (
	action$: Observable<Action>, store,
): Observable<Action> => action$.pipe(
	ofType(JOIN_LEARNING_PATHS_CALL),
	switchMap(
		({ payload }) => {
			const isSignIn = selectors.getIsSignIn(store.value);
			if (!isSignIn) {
				return [
					actions.addActionsToStash([
						{
							actionMethod: 'joinLearningPathCall',
							actionPayload: payload,
						},
						{
							actionMethod: 'historyReplaceCall',
							actionPayload: `/learning-path/${payload.shortLink}`,
						},
					]),
					actions.setModalType({ type: MODAL_TYPE_LOGIN }),
				];
			}
			return fromPromise(joinLearningPaths(payload)).pipe(
				switchMap(res => [
					joinLearningPathDone({ res }),
					learningProgressCall(payload),
				]),
				catchError(error => of(joinLearningPathFail({ error }))),
			);
		},
	),
);

export const leaveLearningPathEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(LEAVE_LEARNING_PATHS_CALL),
	switchMap(
		({ payload }) => fromPromise(leaveLearningPaths(payload)).pipe(
			switchMap(res => [
				leaveLearningPathDone({ res }),
				learningProgressCall(payload),
			]),
			catchError(error => of(leaveLearningPathFail({ error }))),
		),
	),
);

export const learningPathsProgressEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(LEARNING_PATHS_PROGRESS_CALL),
	switchMap(
		({ payload }) => fromPromise(learningPathsProgress(payload)).pipe(
			switchMap(res => of(learningProgressDone({ res }))),
			catchError(error => of(learningProgressFail({ error }))),
		),
	),
);

export const getLearningPathsCommoditiesEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_LEARNING_PATHS_COMMODITIES_CALL),
	switchMap(
		({ payload }) => fromPromise(getLearningPathsCommodities(payload)).pipe(
			switchMap(res => of(getLearningPathsCommoditiesDone({ res }))),
			catchError(error => of(getLearningPathsCommoditiesFail({ error }))),
		),
	),
);

export const postLearningPathsCertificateEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(POST_LEARNING_PATHS_CERTIFICATE_CALL),
	switchMap(
		({ payload }) => fromPromise(postLearningPathsCertificate(payload)).pipe(
			switchMap(res => {
				if (payload.onSuccess) payload.onSuccess();
				return of(postLearningPathsCertificateDone({ res }));
			}),
			catchError(error => of(postLearningPathsCertificateFail({ error }))),
		),
	),
);
