import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, concatMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { course } from '@src/services';

import * as actions from '@src/store/actions';
import { getQuizzesAnswerSheetCall } from '@src/store/actions/quiz';
import * as types from '@src/store/types';
import { TOAST_TYPE_ERROR } from '@src/const/common';

// 首頁
export const getCourseViewCall = (action$, store) => action$.pipe(
	ofType(types.GET_COURSE_VIEW_CALL),
	switchMap(({ payload }) => fromPromise(course.getCourseView(payload)).pipe(
		switchMap(response => {
			const newActions = [actions.getCourseViewDone({ response })];

			return newActions;
		}),
		catchError(err => of(actions.getCourseViewFail(err))),
	)),
);

// 課程頁
export const getCurrentCourseCall = (action$, store) => action$.pipe(
	ofType(types.GET_CURRENT_COURSE_CALL, types.GET_CURRENT_COURSE_CALL_UPDATE),
	concatMap(({ payload }) => fromPromise(course.getCurrentCourse(payload)).pipe(
		switchMap(response => {
			const doneActions = [
				actions.getCurrentCourseDone({
					response,
					courseId: payload.courseId,
				}),
				actions.getCurrentCourseProgressCall({ courseId: payload.courseId }),
			];
			(response?.chapters || []).forEach(chapter => {
				(chapter?.units || []).forEach(unit => {
					if (unit.type === 'quiz' && unit.answer) {
						doneActions.push(getQuizzesAnswerSheetCall({
							quizId: unit.answer.quiz_id,
							sheetId: unit.answer.answer_sheet_id,
							notSaveCurrentSheet: true,
						}));
					}
				});
			});
			return doneActions;
		}),
		catchError(error => of(actions.getCurrentCourseFail({ error, courseId: payload.courseId }))),
	)),
);

export const getCurrentCourseGeneralCall = (action$, store) => action$.pipe(
	ofType(types.GET_CURRENT_COURSE_GENERAL_CALL),
	switchMap(({ payload }) => fromPromise(course.getCurrentCourseGeneral(payload)).pipe(
		switchMap(response => {
			const newActions = [actions.getCurrentCourseGeneralDone({ response, courseId: payload.courseId })];

			return newActions;
		}),
		catchError(err => of(actions.getCurrentCourseGeneralFail(err))),
	)),
);

export const getRefetchCurrentCourseViewCall = (action$, store) => action$.pipe(
	ofType(
		types.LOGOUT_DONE,
		types.EMAIL_LOGIN_DONE,
		types.CHECK_LOGIN_SESSION_DONE,
	),
	switchMap(payload => {
		const courseId = store.value.course.currentCourse?.short_link;

		if (courseId) {
			return [actions.getCurrentCourseCallUpdate({ courseId })];
		}

		return [];
	}),
);

export const getCurrentCourseFail = (action$, store) => action$.pipe(
	ofType(types.GET_CURRENT_COURSE_FAIL),
	switchMap(({ payload }) => {
		const { response = {} } = payload.error;
		const { courseId } = payload;
		const newActions = [];
		const { status } = response;

		// Hardcode to fix url with wrong course id format
		if (!courseId) {
			return [actions.historyPushCall('/')];
		}

		if (status === 400) {
			newActions.push(
				...[
					actions.toastShowCall({
						message: '查無課程, 請確認連結是否正確',
						type: TOAST_TYPE_ERROR,
					}),
					actions.historyPushCall('/'),
				],
			);
		}

		return newActions;
	}),
	catchError(err => {}),
);

export const updateCourseUnitProgressCall = action$ => action$.pipe(
	ofType(types.UPDATE_COURSE_UNIT_PROGRESS_CALL),
	switchMap(({ payload }) => fromPromise(course.updateCourseUnitProgress(payload)).pipe(
		switchMap(res => {
			const nextActions = [
				actions.updateCourseUnitProgressDone({ payload, res }),
			];

			if (payload.isFinished) {
				nextActions.push(
					actions.getCurrentCourseCallUpdate({
						courseId: payload.courseId,
					}),
				);
				nextActions.push(
					actions.getCurrentCourseProgressUpdate({
						courseId: payload.courseId,
					}),
				);
			}

			return nextActions;
		}),
		catchError(() => []),
	)),
);

export const getCurrentCourseProgressCall = action$ => action$.pipe(
	ofType(
		types.GET_CURRENT_COURSE_PROGRESS_CALL,
		types.GET_CURRENT_COURSE_PROGRESS_UPDATE,
	),
	switchMap(({ payload }) => fromPromise(course.getCurrentCourseProgress(payload)).pipe(
		switchMap(res => [actions.getCurrentCourseProgressDone(res)]),
		catchError(err => [actions.getCurrentCourseProgressFail(err)]),
	)),
);

export const getCourseRatingCall = (action$, store) => action$.pipe(
	ofType(types.GET_COURSE_RATING_CALL, types.GET_COURSE_RATING_CALL_UPDATE),
	concatMap(({ payload }) => fromPromise(course.getCourseRating(payload)).pipe(
		switchMap(res => [
			actions.getCourseRatingDone({
				course_id: payload.course_id,
				response: res,
			}),
		]),
		catchError(() => [actions.getCourseRatingFail(payload)]),
	)),
);

export const addCourseRatingCall = (action$, store) => action$.pipe(
	ofType(types.ADD_COURSE_RATING_CALL),
	switchMap(({ payload }) => fromPromise(course.addCourseRating(payload)).pipe(
		switchMap(res => [
			actions.addCourseRatingDone({
				course_id: payload.course_id,
			}),
			actions.getCurrentCourseCallUpdate({
				courseId: payload.course_id,
			}),
			actions.getCourseRatingCallUpdate({
				course_id: payload.course_id,
			}),
			actions.getCourseRatingCall({
				course_id: payload.course_id,
			}),
		]),
		catchError(() => [actions.addCourseRatingFail(payload)]),
	)),
);

export const editCourseRatingCall = (action$, store) => (
	action$.pipe(
		ofType(types.EDIT_COURSE_RATING_CALL),
		switchMap(({ payload }) => (
			fromPromise(course.editCourseRating(payload)).pipe(
				switchMap(res => ([
					actions.editCourseRatingDone({ course_id: payload.course_id }),
					actions.getCurrentCourseCallUpdate({ courseId: payload.course_id }),
					actions.getCourseRatingCallUpdate({ course_id: payload.course_id }),
				])),
				catchError(() => [actions.addCourseRatingFail(payload)]),
			)
		)),
	)
);

export const getPropagandaReviewCall = (action$, store) => (
	action$.pipe(
		ofType(types.GET_PROPAGANDA_REVIEW_CALL),
		switchMap(({ payload }) => (
			fromPromise(course.getPropagandaReview(payload)).pipe(
				switchMap(res => ([
					actions.getPropagandaReviewDone({
						courseId: payload.courseId,
						response: res,
					}),
				])),
				catchError(() => [actions.getPropagandaReviewFail(payload)]),
			)
		)),
	)
);

export const getCourseCertificateEpic = (action$, store) => (
	action$.pipe(
		ofType(types.GET_COURSE_CERTIFICATE_CALL),
		concatMap(({ payload }) => (
			fromPromise(course.getCourseCertificate(payload)).pipe(
				concatMap(res => of(
					actions.getCourseCertificateDone({ response: res, courseId: payload.courseId }),
				)),
				catchError(error => {
					const { createCertificateIfNotFound } = payload;
					const shouldCreateCertificate = error.response?.status === 404;

					if (shouldCreateCertificate && createCertificateIfNotFound) {
						return of(
							actions.createCourseCertificateCall({ courseId: payload.courseId }),
							actions.getCourseCertificateFail({ error, courseId: payload.courseId }),
						);
					}
					return of(
						actions.getCourseCertificateFail({ error, courseId: payload.courseId }),
					);
				}),
			)
		)),
	)
);

export const createCourseCertificateEpic = action$ => (
	action$.pipe(
		ofType(types.CREATE_COURSE_CERTIFICATE_CALL),
		switchMap(({ payload }) => (
			fromPromise(course.createCourseCertificate(payload)).pipe(
				switchMap(res => of(
					actions.createCourseCertificateDone({ response: res, courseId: payload.courseId }),
				)),
				catchError(error => of(
					actions.createCourseCertificateFail({ error, courseId: payload.courseId }),
				)),
			)
		)),
	)
);

export const unitViewedCallEpic = action$ => (
	action$.pipe(
		ofType(types.UNIT_VIEWED_CALL),
		switchMap(({ payload }) => {
			const { onSuccess, ...unitViewedPayload } = payload;
			return (
				fromPromise(course.unitViewed(unitViewedPayload)).pipe(
					switchMap(response => {
						if (onSuccess) {
							onSuccess();
						}
						return of(
							actions.unitViewedDone({ response }),
						);
					}),
					catchError(error => of(
						actions.unitViewedFail({ error }),
					)),
				)
			);
		}),
	)
);

export const unitViewRecordCallEpic = action$ => (
	action$.pipe(
		ofType(types.UNIT_VIEWED_RECORD_CALL),
		switchMap(({ payload }) => (
			fromPromise(course.unitViewedRecord(payload)).pipe(
				switchMap(response => {
					if (payload?.onSuccess) payload.onSuccess();
					return of(
						actions.unitViewRecordDone({ response }),
					);
				}),
				catchError(error => of(
					actions.unitViewRecordFail({ error }),
				)),
			)
		)),
	)
);

export const courseViewRecordCallEpic = action$ => (
	action$.pipe(
		ofType(types.COURSE_VIEWED_RECORD_CALL),
		switchMap(({ payload }) => (
			fromPromise(course.courseViewedRecord(payload)).pipe(
				switchMap(response => of(
					actions.courseViewRecordDone({ response }),
				)),
				catchError(error => of(
					actions.courseViewRecordDone({ error }),
				)),
			)
		)),
	)
);
