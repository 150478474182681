import React from 'react';
import { toast } from 'react-toastify';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_WARN, TOAST_TYPE_ERROR, TOAST_TYPE_INFO } from '@src/const/common';

import MyToast from '@src/components/MyToast';

export const toastShow = ({ type = TOAST_TYPE_SUCCESS, message = '', messageParam, options = { autoClose: 1000 }, actionText, actionTextStyle, actionOnClick }) => {
	const toastDiv = (
		<MyToast
			type={type}
			message={message}
			messageParam={messageParam}
			actionText={actionText}
			actionTextStyle={actionTextStyle}
			actionOnClick={actionOnClick}
		/>
	);

	switch (type) {
		case TOAST_TYPE_SUCCESS:
			toast.success(toastDiv, options);
			break;
		case TOAST_TYPE_WARN:
			toast.warn(toastDiv, options);
			break;
		case TOAST_TYPE_ERROR:
			toast.error(toastDiv, options);
			break;
		case TOAST_TYPE_INFO:
			toast.info(toastDiv, options);
			break;
		default:
			break;
	}
};
