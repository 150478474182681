import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux-actions';

import {
	GET_ATTENDANCE_HISTORY_CALL,
} from '@src/store/types/attendance';

import {
	getAttendanceHistoryDone,
	getAttendanceHistoryFail,
} from '@src/store/actions/attendance';
import {
	getAttendanceHistory,
} from '@src/services/attendance';

export const getAttendanceHistoryEpic = (
	action$: Observable<Action>,
): Observable<Action> => action$.pipe(
	ofType(GET_ATTENDANCE_HISTORY_CALL),
	switchMap(
		({ payload }) => fromPromise(getAttendanceHistory(payload)).pipe(
			switchMap(res => of(getAttendanceHistoryDone({ res }))),
			catchError(error => of(getAttendanceHistoryFail({ error }))),
		),
	),
);
